import { jsx as t, jsxs as C, Fragment as G } from "react/jsx-runtime";
import { AlertGroup as Yt, Alert as Qt, AlertVariant as De, AlertActionCloseButton as Xt, Page as en, Modal as mt, ModalVariant as tn, Button as M, TextContent as nn, Text as Fe, Spinner as gt, FormHelperText as Ue, HelperText as $e, HelperTextItem as Ke, Popover as rn, Icon as Pe, FormGroup as yt, NumberInput as an, ValidatedOptions as Z, InputGroup as Ce, InputGroupItem as Me, TextInput as Ie, Select as ke, MenuToggle as ce, MenuToggleStatus as He, SelectList as we, SelectOption as Ge, TextInputGroup as bt, TextInputGroupMain as vt, ChipGroup as We, Chip as je, TextInputGroupUtilities as Ct, Switch as ln, TextArea as It, Title as sn, Card as on, CardHeader as cn, CardTitle as dn, CardBody as un, Grid as hn, GridItem as at, PageSection as fn, JumpLinks as pn, JumpLinksItem as mn, ButtonVariant as be, Checkbox as gn, Radio as yn, MenuFooter as bn, Dropdown as vn, DropdownList as Cn, DropdownItem as lt, Masthead as In, MastheadToggle as kn, PageToggleButton as wn, MastheadBrand as xn, MastheadContent as Tn, Toolbar as ye, ToolbarContent as Le, ToolbarItem as H, Avatar as Sn, Bullseye as An, EmptyState as En, EmptyStateIcon as st, EmptyStateHeader as Rn, EmptyStateBody as On, EmptyStateFooter as Dn, EmptyStateActions as Fn, SearchInput as Pn, Divider as Mn, Pagination as Ln, Badge as Bn } from "@patternfly/react-core";
import { createContext as kt, useContext as wt, useRef as Y, useEffect as re, useCallback as oe, useState as w, useMemo as N, forwardRef as Vn, useId as xt, Fragment as Tt, Children as St, Component as Nn, isValidElement as _n } from "react";
import { useTranslation as L } from "react-i18next";
import { NetworkError as At } from "@keycloak/keycloak-admin-client";
import qn from "keycloak-js";
import { ExclamationCircleIcon as Un, HelpIcon as it, EyeIcon as $n, EyeSlashIcon as Kn, TimesIcon as Et, CubeIcon as Hn, PaypalIcon as Gn, InstagramIcon as Wn, BitbucketIcon as jn, MicrosoftIcon as zn, TwitterIcon as Jn, StackOverflowIcon as Zn, OpenshiftIcon as Yn, LinkedinIcon as Qn, GoogleIcon as Xn, GitlabIcon as er, FacebookSquareIcon as tr, GithubIcon as nr, MinusCircleIcon as rr, PlusCircleIcon as Rt, EllipsisVIcon as ar, BarsIcon as lr, SearchIcon as sr, SyncAltIcon as ir } from "@patternfly/react-icons";
import { useFormContext as xe, Controller as ae, useController as ze, FormProvider as or, useWatch as cr } from "react-hook-form";
import { get as q, cloneDeep as dr, differenceBy as ur } from "lodash-es";
import me from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as hr } from "@patternfly/react-styles";
import { TableVariant as fr, TableText as pr } from "@patternfly/react-table";
import { Table as mr, TableHeader as gr, TableBody as yr } from "@patternfly/react-table/deprecated";
import './main.css';function Je(e, n) {
  const r = kt(n);
  return r.displayName = e, r;
}
const br = ["error", "errorMessage"], Oe = "error_description";
function vr(e) {
  if (typeof e == "string")
    return e;
  if (e instanceof At)
    return Ir(e.responseData);
  if (e instanceof Error)
    return e.message;
  throw new Error("Unable to determine error message.");
}
function Cr(e) {
  if (!(e instanceof At))
    return;
  const n = e.responseData;
  return Ot(n);
}
function Ot(e) {
  if (typeof e == "object" && e !== null && Oe in e && typeof e[Oe] == "string")
    return e[Oe];
}
function Ir(e) {
  if (!(typeof e != "object" || e === null))
    for (const n of br) {
      const r = e[n];
      if (typeof r == "string")
        return r;
    }
}
const kr = () => Math.floor(Math.random() * 1e3);
function wr(e) {
  return e != null;
}
function Ze(e) {
  const n = wt(e);
  if (wr(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function xr() {
  const e = Y(!1), n = Y(/* @__PURE__ */ new Set());
  re(() => (e.current = !1, () => {
    e.current = !0, r();
  }), []);
  function r() {
    n.current.forEach((a) => clearTimeout(a)), n.current.clear();
  }
  return oe((a, l) => {
    if (e.current)
      throw new Error("Can't schedule a timeout on an unmounted component.");
    const s = Number(setTimeout(i, l));
    n.current.add(s);
    function i() {
      n.current.delete(s), a();
    }
    return function() {
      clearTimeout(s), n.current.delete(s);
    };
  }, []);
}
function Tr({ alerts: e, onCloseAlert: n }) {
  return /* @__PURE__ */ t(
    Yt,
    {
      "data-testid": "global-alerts",
      isToast: !0,
      style: { whiteSpace: "pre-wrap" },
      children: e.map(({ id: r, variant: a, message: l, description: s }, i) => /* @__PURE__ */ t(
        Qt,
        {
          "data-testid": i === 0 ? "last-alert" : void 0,
          isLiveRegion: !0,
          variant: De[a],
          component: "p",
          variantLabel: "",
          title: l,
          actionClose: /* @__PURE__ */ t(
            Xt,
            {
              title: l,
              onClose: () => n(r)
            }
          ),
          children: s && /* @__PURE__ */ t("p", { children: s })
        },
        r
      ))
    }
  );
}
const Sr = 8e3, Dt = Je(
  "AlertContext",
  void 0
), Ma = () => Ze(Dt), Ar = ({ children: e }) => {
  const { t: n } = L(), r = xr(), [a, l] = w([]), s = (c) => l((h) => h.filter((d) => d.id !== c)), i = oe(
    (c, h = De.success, d) => {
      const p = {
        id: kr(),
        message: c,
        variant: h,
        description: d
      };
      l((f) => [p, ...f]), r(() => s(p.id), Sr);
    },
    [r]
  ), o = oe(
    (c, h) => {
      const d = n(c, { error: vr(h) }), p = Cr(h);
      i(d, De.danger, p);
    },
    [i, n]
  ), u = N(() => ({ addAlert: i, addError: o }), [i, o]);
  return /* @__PURE__ */ C(Dt.Provider, { value: u, children: [
    /* @__PURE__ */ t(Tr, { alerts: a, onCloseAlert: s }),
    e
  ] });
}, Er = (e) => {
  const { t: n } = L(), r = e.error, a = Rr(r) || Ot(r)?.replace(/\+/g, " ");
  console.error(r);
  function l() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(en, { children: /* @__PURE__ */ t(
    mt,
    {
      variant: tn.small,
      title: a ? "" : n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(M, { variant: "primary", onClick: l, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ t(nn, { children: a ? /* @__PURE__ */ t(Fe, { children: n(a) }) : /* @__PURE__ */ t(Fe, { children: n("somethingWentWrongDescription") }) })
    }
  ) });
};
function Rr(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function Or(e, n, r) {
  const [a, l] = w(
    () => e.getItem(n) ?? r
  ), s = oe((i) => {
    l(i), e.setItem(n, i);
  }, []);
  return re(() => {
    l(e.getItem(n) ?? r), window.addEventListener("storage", i);
    function i(o) {
      o.storageArea === e && (o.key === null || o.key === n) && l(o.newValue ?? r);
    }
    return () => window.removeEventListener("storage", i);
  }, [e, n]), [a, s];
}
function Ft(e, n, r) {
  const a = N(
    () => JSON.stringify(r),
    [r]
  ), [l, s] = Or(
    e,
    n,
    a
  ), i = N(() => JSON.parse(l), [l]), o = oe(
    (u) => s(JSON.stringify(u)),
    []
  );
  return [i, o];
}
const Pt = Je(
  "HelpContext",
  void 0
), Dr = () => Ze(Pt), Fr = ({ children: e }) => {
  const [n, r] = Ft(localStorage, "helpEnabled", !0);
  function a() {
    r(!n);
  }
  return /* @__PURE__ */ t(Pt.Provider, { value: { enabled: n, toggleHelp: a }, children: e });
}, Pr = () => kt(void 0);
let Be;
const La = () => {
  const e = wt(Be);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, Ba = ({
  environment: e,
  children: n
}) => {
  Be = Pr();
  const r = Y(!1), [a, l] = w(!1), [s, i] = w(), o = N(() => {
    const c = new qn({
      url: e.serverBaseUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return c.onAuthLogout = () => c.login(), c;
  }, [e]);
  re(() => {
    if (r.current)
      return;
    o.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query",
      scope: e.scope
    }).then(() => l(!0)).catch((h) => i(h)), r.current = !0;
  }, [o]);
  const u = new URLSearchParams(window.location.search);
  return s || u.get("error_description") ? /* @__PURE__ */ t(
    Er,
    {
      error: s || u.get("error_description")
    }
  ) : a ? /* @__PURE__ */ t(Be.Provider, { value: { environment: e, keycloak: o }, children: /* @__PURE__ */ t(Ar, { children: /* @__PURE__ */ t(Fr, { children: n }) }) }) : /* @__PURE__ */ t(gt, {});
};
function Va() {
  const n = document.getElementById("environment")?.textContent;
  if (typeof n != "string")
    throw new Error("Environment variables not found in the document.");
  try {
    return JSON.parse(n);
  } catch {
    throw new Error("Unable to parse environment variables as JSON.");
  }
}
const Na = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: s,
  buttonTestRole: i,
  onContinue: o,
  component: u = M,
  children: c,
  ...h
}) => {
  const [d, p] = w(!1);
  return /* @__PURE__ */ C(G, { children: [
    /* @__PURE__ */ t(
      u,
      {
        variant: s,
        onClick: () => p(!0),
        isDisabled: l,
        "data-testrole": i,
        children: a
      }
    ),
    /* @__PURE__ */ t(
      mt,
      {
        variant: "small",
        ...h,
        title: e,
        isOpen: d,
        onClose: () => p(!1),
        actions: [
          /* @__PURE__ */ t(
            M,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                p(!1), o();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            M,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => p(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: c
      }
    )
  ] });
}, Mt = ({ message: e, ...n }) => /* @__PURE__ */ t(Ue, { ...n, children: /* @__PURE__ */ t($e, { children: /* @__PURE__ */ t(Ke, { icon: /* @__PURE__ */ t(Un, {}), variant: "error", children: e }) }) }), Lt = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = Dr();
  return l ? /* @__PURE__ */ t(rn, { bodyContent: e, children: /* @__PURE__ */ C(G, { children: [
    !a && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (s) => s.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(Pe, { isInline: r, children: /* @__PURE__ */ t(it, {}) })
      }
    ),
    a && /* @__PURE__ */ t(Pe, { isInline: r, children: /* @__PURE__ */ t(it, {}) })
  ] }) }) : null;
};
function de(e) {
  if (!(typeof e > "u" || e instanceof RegExp))
    return typeof e == "object" ? e.value : e;
}
const ne = ({
  name: e,
  label: n,
  labelIcon: r,
  error: a,
  children: l,
  ...s
}) => /* @__PURE__ */ C(
  yt,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(Lt, { helpText: r, fieldLabelId: e }) : void 0,
    ...s,
    children: [
      l,
      a && /* @__PURE__ */ t(Mt, { "data-testid": `${e}-helper`, message: a.message })
    ]
  }
), _a = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: a,
  ...l
}) => {
  const {
    control: s,
    formState: { errors: i }
  } = xe();
  return /* @__PURE__ */ t(
    ne,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: i[e],
      labelIcon: a,
      children: /* @__PURE__ */ t(
        ae,
        {
          ...r,
          name: e,
          control: s,
          render: ({ field: o }) => {
            const u = !!r.rules?.required, c = de(r.rules?.min), h = o.value ?? r.defaultValue, d = (p) => o.onChange(
              c !== void 0 ? Math.max(p, Number(c)) : p
            );
            return /* @__PURE__ */ t(
              an,
              {
                ...l,
                id: e,
                value: h,
                validated: i[e] ? Z.error : Z.default,
                required: u,
                min: Number(c),
                max: Number(r.rules?.max),
                onPlus: () => d(h + 1),
                onMinus: () => d(h - 1),
                onChange: (p) => {
                  const f = Number(p.currentTarget.value);
                  d(isNaN(f) ? r.defaultValue : f);
                }
              }
            );
          }
        }
      )
    }
  );
}, Mr = ({
  hasReveal: e = !0,
  innerRef: n,
  isTideIdp: r = !1,
  ...a
}) => {
  const { t: l } = L(), [s, i] = w(!0);
  return /* @__PURE__ */ t(G, { children: /* @__PURE__ */ C(Ce, { style: { display: r ? "none" : void 0 }, children: [
    /* @__PURE__ */ t(Me, { isFill: !0, children: /* @__PURE__ */ t(
      Ie,
      {
        ...a,
        type: s ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      M,
      {
        variant: "control",
        "aria-label": l("showPassword"),
        onClick: () => i(!s),
        children: s ? /* @__PURE__ */ t($n, {}) : /* @__PURE__ */ t(Kn, {})
      }
    )
  ] }) });
}, Bt = Vn(
  (e, n) => /* @__PURE__ */ t(Mr, { ...e, innerRef: n })
);
Bt.displayName = "PasswordInput";
const qa = (e) => {
  const { labelIcon: n, ...r } = e, a = !!de(e.rules?.required), l = e.defaultValue ?? "", { field: s, fieldState: i } = ze({
    ...e,
    defaultValue: l
  });
  return /* @__PURE__ */ C(
    ne,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: a,
      error: i.error,
      children: [
        /* @__PURE__ */ t(
          Bt,
          {
            isRequired: a,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? Z.error : Z.default,
            isDisabled: e.isDisabled,
            ...r,
            ...s
          }
        ),
        e.helperText && /* @__PURE__ */ t(Ue, { children: /* @__PURE__ */ t($e, { children: /* @__PURE__ */ t(Ke, { children: e.helperText }) }) })
      ]
    }
  );
}, Lr = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: s,
  ...i
}) => {
  const {
    control: o,
    formState: { errors: u }
  } = xe(), [c, h] = w(!1), d = de(l.rules?.required) === !0;
  return /* @__PURE__ */ t(
    ne,
    {
      name: n,
      label: r,
      isRequired: d,
      error: q(u, n),
      labelIcon: s,
      children: /* @__PURE__ */ t(
        ae,
        {
          ...l,
          name: n,
          control: o,
          render: ({ field: { onChange: p, value: f } }) => /* @__PURE__ */ t(
            ke,
            {
              ...i,
              onClick: () => h(!c),
              onOpenChange: () => h(!1),
              selected: ie(a) ? a.filter(
                (g) => Array.isArray(f) ? f.includes(g.key) : f === g.key
              ).map((g) => g.value) : f,
              toggle: (g) => /* @__PURE__ */ t(
                ce,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: g,
                  onClick: () => h(!c),
                  isExpanded: c,
                  isFullWidth: !0,
                  status: q(u, n) ? He.danger : void 0,
                  "aria-label": "toggle",
                  children: ie(a) ? a.find(
                    (T) => T.key === (Array.isArray(f) ? f[0] : f)
                  )?.value : f
                }
              ),
              onSelect: (g, T) => {
                const b = T?.toString();
                p(Array.isArray(f) ? [b] : b), h(!1);
              },
              isOpen: c,
              children: /* @__PURE__ */ t(we, { "data-testid": `select-${n}`, children: a.map((g) => /* @__PURE__ */ t(Ge, { value: ee(g), children: Ye(g) ? g : g.value }, ee(g))) })
            }
          )
        }
      )
    }
  );
}, ge = (e) => Ye(e) ? e : e.value, Br = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: s,
  placeholderText: i,
  onFilter: o,
  variant: u,
  ...c
}) => {
  const {
    control: h,
    formState: { errors: d }
  } = xe(), [p, f] = w(!1), [g, T] = w(""), [b, y] = w(0), R = Y(), O = de(l.rules?.required) === !0, k = u === se.typeaheadMulti, E = a.filter(
    (m) => ge(m).toLowerCase().startsWith(g.toLowerCase())
  ), U = N(
    () => E.map((m, S) => /* @__PURE__ */ t(
      Ge,
      {
        value: ee(m),
        isFocused: b === S,
        children: ge(m)
      },
      ee(m)
    )),
    [b, E]
  ), B = (m, S) => {
    const F = E[b];
    switch (f(!0), m.key) {
      case "Enter": {
        m.preventDefault(), T(k ? "" : ge(F)), S.onChange(
          Array.isArray(S.value) ? [...S.value, ee(F)] : ee(F)
        ), f(!1), y(0);
        break;
      }
      case "Tab":
      case "Escape": {
        f(!1), S.onChange(void 0);
        break;
      }
      case "Backspace": {
        u === se.typeahead && S.onChange("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        m.preventDefault();
        let I = 0;
        m.key === "ArrowUp" && (b === 0 ? I = a.length - 1 : I = b - 1), m.key === "ArrowDown" && (b === a.length - 1 ? I = 0 : I = b + 1), y(I);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    ne,
    {
      name: n,
      label: r,
      isRequired: O,
      error: q(d, n),
      labelIcon: s,
      children: /* @__PURE__ */ t(
        ae,
        {
          ...l,
          name: n,
          control: h,
          render: ({ field: m }) => /* @__PURE__ */ t(
            ke,
            {
              ...c,
              onOpenChange: () => f(!1),
              selected: ie(a) ? a.filter(
                (S) => Array.isArray(m.value) ? m.value.includes(S.key) : m.value === S.key
              ).map((S) => S.value) : m.value,
              shouldFocusFirstItemOnOpen: !1,
              toggle: (S) => /* @__PURE__ */ t(
                ce,
                {
                  ref: S,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => {
                    f(!p), R.current?.focus();
                  },
                  isExpanded: p,
                  isFullWidth: !0,
                  status: q(d, n) ? He.danger : void 0,
                  children: /* @__PURE__ */ C(bt, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      vt,
                      {
                        placeholder: i,
                        value: u === se.typeahead && m.value ? ie(a) ? a.find(
                          (F) => F.key === (Array.isArray(m.value) ? m.value[0] : m.value)
                        )?.value : m.value : g,
                        onClick: () => f(!p),
                        onChange: (F, I) => {
                          T(I), o?.(I);
                        },
                        onKeyDown: (F) => B(F, m),
                        autoComplete: "off",
                        innerRef: R,
                        role: "combobox",
                        isExpanded: p,
                        "aria-controls": "select-typeahead-listbox",
                        children: u === se.typeaheadMulti && Array.isArray(m.value) && /* @__PURE__ */ t(We, { "aria-label": "Current selections", children: m.value.map(
                          (F, I) => /* @__PURE__ */ t(
                            je,
                            {
                              onClick: (D) => {
                                D.stopPropagation(), m.onChange(
                                  m.value.filter(
                                    (P) => P !== ee(F)
                                  )
                                );
                              },
                              children: ie(a) ? a.find((D) => F === D.key)?.value : ge(F)
                            },
                            I
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(Ct, { children: (!!g || m.value) && /* @__PURE__ */ t(
                      M,
                      {
                        variant: "plain",
                        onClick: () => {
                          T(""), m.onChange(k ? [] : ""), R?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(Et, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (S, F) => {
                S?.stopPropagation();
                const I = F?.toString();
                k && Array.isArray(m.value) ? m.value.includes(I) ? m.onChange(
                  m.value.filter((D) => D !== I)
                ) : m.onChange([...m.value, I]) : (m.onChange(Array.isArray(m.value) ? [I] : I), f(!1));
              },
              isOpen: p,
              children: /* @__PURE__ */ t(we, { children: U })
            }
          )
        }
      )
    }
  );
};
var se = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(se || {});
const ie = (e) => typeof e[0] != "string", Ye = (e) => typeof e == "string", ee = (e) => Ye(e) ? e : e.key, Vr = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(Lr, { ...n }) : /* @__PURE__ */ t(Br, { ...n, variant: e }), Ua = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: a,
  ...l
}) => {
  const i = r ?? (n ? "false" : !1), { control: o } = xe();
  return /* @__PURE__ */ t(
    ne,
    {
      hasNoPaddingTop: !0,
      name: l.name,
      isRequired: l.rules?.required === !0,
      label: l.label,
      labelIcon: a,
      children: /* @__PURE__ */ t(
        ae,
        {
          control: o,
          name: l.name,
          defaultValue: i,
          render: ({ field: { onChange: u, value: c } }) => /* @__PURE__ */ t(
            ln,
            {
              ...l,
              id: l.name,
              "data-testid": l.name,
              label: e,
              isChecked: n ? c === "true" : c,
              onChange: (h, d) => {
                const p = n ? d.toString() : d;
                l.onChange?.(h, d), u(p);
              }
            }
          )
        }
      )
    }
  );
}, $a = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: a, fieldState: l } = ze({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    ne,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ t(
        It,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? Z.error : Z.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, Ka = (e) => {
  const { labelIcon: n, helperText: r, ...a } = e, l = !!de(e.rules?.required), s = e.defaultValue ?? "", { field: i, fieldState: o } = ze({
    ...e,
    defaultValue: s
  });
  return /* @__PURE__ */ C(
    ne,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: o.error,
      children: [
        /* @__PURE__ */ t(
          Ie,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e["data-testid"] || e.name,
            validated: o.error ? Z.error : Z.default,
            isDisabled: e.isDisabled,
            ...a,
            ...i
          }
        ),
        r && /* @__PURE__ */ t(Ue, { children: /* @__PURE__ */ t($e, { children: /* @__PURE__ */ t(Ke, { children: r }) }) })
      ]
    }
  );
}, Nr = It, _r = "data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20id='Layer_1'%20xmlns='http://www.w3.org/2000/svg'%20version='1.1'%20viewBox='0%200%20224%20277'%3e%3c!--%20Generator:%20Adobe%20Illustrator%2029.1.0,%20SVG%20Export%20Plug-In%20.%20SVG%20Version:%202.1.0%20Build%20142)%20--%3e%3cdefs%3e%3cstyle%3e%20.st0%20{%20fill:%20%23231f20;%20}%20%3c/style%3e%3c/defs%3e%3cpath%20class='st0'%20d='M94,50.9h36.5V18.6c0-1.8-1.4-3.2-3.2-3.2H20.6c-1.7,0-3.2,1.4-3.2,3.2v29.2c0,1.7,1.4,3.2,3.2,3.2h73.4Z'/%3e%3cpath%20class='st0'%20d='M204.3,15.4h-11.9c0,0-.2,0-.2.1l-61.7,35.4h0c-.6.2-1.2.4-1.7.7l-34.8,20v171.1l32,18.6c1.6.8,3.4.2,4.2-1.4.2-.4.3-.9.3-1.3V50.9h73.4c1.7,0,3.2-1.4,3.2-3.2v-29.2c0-1.6-1.2-3-2.8-3.2Z'/%3e%3c/svg%3e", Ha = ({ icon: e }) => {
  const n = qr(e);
  return /* @__PURE__ */ t(Pe, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function qr(e) {
  switch (e) {
    case "github":
      return nr;
    case "facebook":
      return tr;
    case "gitlab":
      return er;
    case "google":
      return Xn;
    case "linkedin":
    case "linkedin-openid-connect":
      return Qn;
    case "openshift-v4":
      return Yn;
    case "stackoverflow":
      return Zn;
    case "twitter":
      return Jn;
    case "microsoft":
      return zn;
    case "bitbucket":
      return jn;
    case "instagram":
      return Wn;
    case "paypal":
      return Gn;
    case "tide":
      return () => /* @__PURE__ */ t("img", { src: _r, alt: "Tide Icon", width: "16", height: "16" });
    default:
      return Hn;
  }
}
const Ur = "_title_180i0_2", $r = {
  title: Ur
}, Vt = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ t(
  sn,
  {
    headingLevel: r,
    size: a,
    className: $r.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: n
  }
), Kr = ({
  title: e,
  children: n,
  scrollId: r,
  className: a
}) => {
  const l = xt();
  return /* @__PURE__ */ C(on, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ t(cn, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(dn, { tabIndex: 0, children: /* @__PURE__ */ t(Vt, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(un, { className: "kc-form-panel__body", children: n })
  ] });
}, Hr = (e) => {
  const { title: n, children: r, scrollId: a, ...l } = e;
  return /* @__PURE__ */ t("section", { ...l, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ C(G, { children: [
    /* @__PURE__ */ t(Vt, { id: a, title: n }),
    r
  ] }) });
}, Gr = "_panel_1cdve_1", Wr = "_sticky_1cdve_5", ot = {
  panel: Gr,
  sticky: Wr
}, jr = "kc-main-content-page-container", ct = (e) => e.replace(/\s+/g, "-"), zr = ({
  label: e,
  sections: n,
  borders: r = !1,
  ...a
}) => {
  const l = N(
    () => n.filter(({ isHidden: s }) => !s),
    [n]
  );
  return /* @__PURE__ */ C(hn, { hasGutter: !0, ...a, children: [
    /* @__PURE__ */ t(at, { md: 8, sm: 12, children: l.map(({ title: s, panel: i }) => {
      const o = ct(s.toLowerCase());
      return /* @__PURE__ */ t(Tt, { children: r ? /* @__PURE__ */ t(
        Kr,
        {
          scrollId: o,
          title: s,
          className: ot.panel,
          children: i
        }
      ) : /* @__PURE__ */ t(Hr, { scrollId: o, title: s, children: i }) }, s);
    }) }),
    /* @__PURE__ */ t(at, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ t(fn, { className: ot.sticky, children: /* @__PURE__ */ t(
      pn,
      {
        isVertical: !0,
        scrollableSelector: `#${jr}`,
        label: e,
        offset: 100,
        children: l.map(({ title: s }) => {
          const i = ct(s.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ t(
              mn,
              {
                href: `#${i}`,
                "data-testid": `jump-link-${i}`,
                children: s
              },
              s
            )
          );
        })
      }
    ) }) })
  ] });
}, Jr = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, Ga = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: a = !1,
  children: l,
  ...s
}) => /* @__PURE__ */ t(
  M,
  {
    variant: "primary",
    isDisabled: e && !Jr(e, a, r) || n,
    ...s,
    type: "submit",
    children: l
  }
), Zr = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, Yr = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: a
}) => {
  const l = N(
    () => r.map((s) => ({
      key: s,
      value: e(`locale_${s}`, Zr(s) ?? s)
    })).sort((s, i) => s.value.localeCompare(i.value, a)),
    [r, a, e]
  );
  return l.length ? /* @__PURE__ */ t(or, { ...n, children: /* @__PURE__ */ t(
    Vr,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: l,
      variant: l.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, Ve = (e) => e?.includes("${"), Ne = (e) => e.substring(2, e.length - 1), te = (e, n, r, a) => {
  const l = n || r, s = Ve(l) ? Ne(l) : l, i = a ? `${a}.${s}` : s;
  return e(i || "");
}, _e = (e, n) => te(e, n.displayName, n.name), Qr = ["username", "firstName", "lastName", "email"], Nt = (e) => e && Qr.includes(e), Q = (e) => `${Nt(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, Wa = (e) => e.replaceAll(".", "🍺"), ja = (e) => e.replaceAll("🍺", ".");
function za(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((s) => Ve(s.toString()) ? r(Ne(s)) : s)
    );
    n(Q(a.field), {
      message: r(
        Ve(a.errorMessage) ? Ne(a.errorMessage) : a.errorMessage,
        {
          ...l,
          defaultValue: a.errorMessage || a.field
        }
      ),
      type: "server"
    });
  });
}
function Te({
  required: e
}) {
  return e;
}
function Ja(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return dt(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(dt);
}
function dt(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const ue = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  children: l
}) => {
  const s = te(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: i }
  } = n, o = a?.(r), u = q(i, Q(r.name));
  return /* @__PURE__ */ C(
    yt,
    {
      label: _e(e, r) || "",
      fieldId: r.name,
      isRequired: Te(r),
      labelIcon: s ? /* @__PURE__ */ t(Lt, { helpText: s, fieldLabelId: r.name }) : void 0,
      children: [
        o ? /* @__PURE__ */ C(Ce, { children: [
          l,
          o
        ] }) : l,
        u && /* @__PURE__ */ t(
          Mt,
          {
            "data-testid": `${r.name}-helper`,
            message: u.message
          }
        )
      ]
    },
    r.name
  );
}, Xr = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  ...l
}) => /* @__PURE__ */ t(ue, { t: e, form: n, attribute: r, renderer: a, children: /* @__PURE__ */ t(
  ea,
  {
    t: e,
    form: n,
    "aria-label": _e(e, r),
    name: Q(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: _e(e, r)
    }),
    ...l
  }
) }), ea = ({
  t: e,
  name: n,
  inputType: r,
  form: a,
  addButtonLabel: l,
  isDisabled: s = !1,
  defaultValue: i,
  id: o,
  ...u
}) => {
  const { register: c, setValue: h, control: d } = a, p = cr({
    name: n,
    control: d,
    defaultValue: i || ""
  }), f = N(() => Array.isArray(p) && p.length !== 0 ? p : i || [""], [p]), g = (O) => {
    y([...f.slice(0, O), ...f.slice(O + 1)]);
  }, T = () => {
    y([...f, ""]);
  }, b = (O, k) => {
    y([...f.slice(0, O), k, ...f.slice(O + 1)]);
  }, y = (O) => {
    const k = O.flatMap((E) => E);
    h(n, k, {
      shouldDirty: !0
    });
  }, R = r.startsWith("html") ? r.substring(6) : "text";
  return re(() => {
    c(n);
  }, [c]), /* @__PURE__ */ t("div", { id: o, children: f.map((O, k) => /* @__PURE__ */ C(Tt, { children: [
    /* @__PURE__ */ C(Ce, { children: [
      /* @__PURE__ */ t(Me, { isFill: !0, children: /* @__PURE__ */ t(
        Ie,
        {
          "data-testid": n + k,
          onChange: (E, U) => b(k, U),
          name: `${n}.${k}.value`,
          value: O,
          isDisabled: s,
          type: R,
          ...u
        }
      ) }),
      /* @__PURE__ */ t(Me, { children: /* @__PURE__ */ t(
        M,
        {
          "data-testid": "remove" + k,
          variant: be.link,
          onClick: () => g(k),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: f.length === 1 || s,
          children: /* @__PURE__ */ t(rr, {})
        }
      ) })
    ] }),
    k === f.length - 1 && /* @__PURE__ */ C(
      M,
      {
        variant: be.link,
        onClick: T,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !O || s,
        children: [
          /* @__PURE__ */ t(Rt, {}),
          " ",
          e(l || "add")
        ]
      }
    )
  ] }, k)) });
}, ut = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = Te(a), s = r.startsWith("multiselect"), i = s ? gn : yn, o = a.validators?.options?.options || [], u = a.annotations?.inputOptionLabels || {}, c = a.annotations?.inputOptionLabelsI18nPrefix;
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    ae,
    {
      name: Q(a.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: h }) => /* @__PURE__ */ t(G, { children: o.map((d) => /* @__PURE__ */ t(
        i,
        {
          id: d,
          "data-testid": d,
          label: te(e.t, u[d], d, c),
          value: d,
          isChecked: h.value.includes(d),
          onChange: () => {
            s ? h.value.includes(d) ? h.onChange(
              h.value.filter((p) => p !== d)
            ) : h.onChange([...h.value, d]) : h.onChange([d]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        d
      )) })
    }
  ) });
}, ta = ({
  toggleId: e,
  onToggle: n,
  onSelect: r,
  selections: a,
  isOpen: l,
  menuAppendTo: s,
  direction: i,
  width: o,
  maxHeight: u,
  toggleIcon: c,
  className: h,
  isDisabled: d,
  children: p,
  ...f
}) => {
  const [g, T] = w(!1), b = Y(), y = () => {
    T(!g), n(!g);
  }, R = () => s === "parent" && b.current?.parentElement || "inline", O = St.toArray(
    p
  );
  return /* @__PURE__ */ t(
    ke,
    {
      ref: b,
      maxMenuHeight: ve(u),
      isScrollable: !0,
      popperProps: {
        appendTo: R(),
        direction: i,
        width: ve(o)
      },
      ...f,
      onClick: y,
      onOpenChange: (k) => {
        k !== g && y();
      },
      selected: a,
      onSelect: (k, E) => {
        r?.(E || ""), y();
      },
      toggle: (k) => /* @__PURE__ */ t(
        ce,
        {
          id: e,
          ref: k,
          className: h,
          onClick: y,
          isExpanded: l,
          "aria-label": f["aria-label"],
          icon: c,
          isDisabled: d,
          isFullWidth: !0,
          children: O.find((E) => E.props.value === a)?.props.children || a || f["aria-label"]
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(we, { children: p })
    }
  );
}, na = ({
  toggleId: e,
  onSelect: n,
  onToggle: r,
  onFilter: a,
  variant: l,
  validated: s,
  placeholderText: i,
  maxHeight: o,
  width: u,
  toggleIcon: c,
  direction: h,
  selections: d,
  typeAheadAriaLabel: p,
  chipGroupComponent: f,
  chipGroupProps: g,
  footer: T,
  isDisabled: b,
  children: y,
  ...R
}) => {
  const [O, k] = w(""), [E, U] = w(0), B = Y(), m = St.toArray(
    y
  ), S = () => {
    r?.(!R.isOpen);
  }, F = (I) => {
    const D = m[E];
    switch (r?.(!0), I.key) {
      case "Enter": {
        I.preventDefault(), l !== J.typeaheadMulti ? k(D.props.value) : k(""), n?.(D.props.value), r?.(!1), U(0);
        break;
      }
      case "Escape": {
        r?.(!1);
        break;
      }
      case "Backspace": {
        l === J.typeahead && n?.("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        I.preventDefault();
        let P = 0;
        I.key === "ArrowUp" && (E === 0 ? P = m.length - 1 : P = E - 1), I.key === "ArrowDown" && (E === m.length - 1 ? P = 0 : P = E + 1), U(P);
        break;
      }
    }
  };
  return /* @__PURE__ */ C(
    ke,
    {
      ...R,
      onClick: S,
      onOpenChange: (I) => r?.(I),
      onSelect: (I, D) => n?.(D || ""),
      maxMenuHeight: ve(o),
      popperProps: { direction: h, width: ve(u) },
      toggle: (I) => /* @__PURE__ */ t(
        ce,
        {
          ref: I,
          id: e,
          variant: "typeahead",
          onClick: () => r?.(!0),
          icon: c,
          isDisabled: b,
          isExpanded: R.isOpen,
          isFullWidth: !0,
          status: s === "error" ? He.danger : void 0,
          children: /* @__PURE__ */ C(bt, { isPlain: !0, children: [
            /* @__PURE__ */ t(
              vt,
              {
                placeholder: i,
                value: l === J.typeahead && d ? d : O,
                onClick: S,
                onChange: (D, P) => {
                  k(P), a?.(P);
                },
                onKeyDown: (D) => F(D),
                autoComplete: "off",
                innerRef: B,
                role: "combobox",
                isExpanded: R.isOpen,
                "aria-controls": "select-typeahead-listbox",
                "aria-label": p,
                children: l === J.typeaheadMulti && Array.isArray(d) && (f || /* @__PURE__ */ t(We, { ...g, children: d.map((D, P) => /* @__PURE__ */ t(
                  je,
                  {
                    onClick: (Se) => {
                      Se.stopPropagation(), n?.(D);
                    },
                    children: D
                  },
                  P
                )) }))
              }
            ),
            /* @__PURE__ */ t(Ct, { children: !!O && /* @__PURE__ */ t(
              M,
              {
                variant: "plain",
                onClick: () => {
                  n?.(""), k(""), a?.(""), B?.current?.focus();
                },
                "aria-label": "Clear input value",
                children: /* @__PURE__ */ t(Et, { "aria-hidden": !0 })
              }
            ) })
          ] })
        }
      ),
      children: [
        /* @__PURE__ */ t(we, { children: y }),
        T && /* @__PURE__ */ t(bn, { children: T })
      ]
    }
  );
};
var J = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(J || {});
const ve = (e) => typeof e == "number" ? e + "px" : e, ra = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(ta, { ...n }) : /* @__PURE__ */ t(na, { ...n, variant: e }), ht = (e) => {
  const { t: n, form: r, inputType: a, attribute: l } = e, [s, i] = w(!1), [o, u] = w(""), c = a === "multiselect", h = (b, y) => {
    c ? y.value.includes(b) ? y.onChange(y.value.filter((R) => R !== b)) : Array.isArray(y.value) ? y.onChange([...y.value, b]) : y.onChange([b]) : y.onChange(b === y.value ? "" : b);
  }, d = l.validators?.options?.options || [], p = l.annotations?.inputOptionLabels || {}, f = l.annotations?.inputOptionLabelsI18nPrefix, g = (b) => te(e.t, p[b], b, f), T = (b) => d.filter(
    (y) => g(y).toLowerCase().includes(o.toLowerCase())
  ).map((y) => /* @__PURE__ */ t(
    Ge,
    {
      selected: b === y,
      value: y,
      children: g(y)
    },
    y
  ));
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    ae,
    {
      name: Q(l.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: b }) => /* @__PURE__ */ t(
        ra,
        {
          toggleId: l.name,
          onToggle: (y) => i(y),
          onClear: () => h("", b),
          onSelect: (y) => {
            const R = y.toString();
            h(R, b), Array.isArray(b.value) || i(!1);
          },
          selections: c && Array.isArray(b.value) ? b.value.map((y) => g(y)) : g(b.value),
          variant: c ? J.typeaheadMulti : d.length >= 10 ? J.typeahead : J.single,
          "aria-label": n("selectOne"),
          isOpen: s,
          isDisabled: l.readOnly,
          onFilter: (y) => (u(y), T(b.value)),
          children: T(b.value)
        }
      )
    }
  ) });
}, aa = (e) => {
  const { form: n, attribute: r } = e, a = Te(r);
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    Nr,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(Q(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: a
    }
  ) });
}, _ = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = Te(a), s = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    Ie,
    {
      id: a.name,
      "data-testid": a.name,
      type: s,
      placeholder: a.readOnly ? "" : te(
        e.t,
        a.annotations?.inputTypePlaceholder,
        "",
        a.annotations?.inputOptionLabelsI18nPrefix
      ),
      readOnly: a.readOnly,
      isRequired: l,
      ...n.register(Q(a.name))
    }
  ) });
}, qe = {
  text: _,
  textarea: aa,
  select: ht,
  "select-radiobuttons": ut,
  multiselect: ht,
  "multiselect-checkboxes": ut,
  "html5-email": _,
  "html5-tel": _,
  "html5-url": _,
  "html5-number": _,
  "html5-range": _,
  "html5-datetime-local": _,
  "html5-date": _,
  "html5-month": _,
  "html5-time": _,
  "multi-input": Xr
}, Za = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: a,
  currentLocale: l,
  hideReadOnly: s = !1,
  renderer: i
}) => {
  const o = N(() => {
    if (!r.attributes)
      return [];
    const u = s ? r.attributes.filter(({ readOnly: c }) => !c) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((c) => ({
      group: c,
      attributes: u.filter(
        (h) => h.group === c.name
      )
    }));
  }, [
    s,
    r.groups,
    r.attributes
  ]);
  return o.length === 0 ? null : /* @__PURE__ */ t(
    zr,
    {
      label: e("jumpToSection"),
      sections: o.filter((u) => u.attributes.length > 0).map(({ group: u, attributes: c }) => ({
        title: te(e, u.displayHeader, u.name) || e("general"),
        panel: /* @__PURE__ */ C("div", { className: "pf-v5-c-form", children: [
          u.displayDescription && /* @__PURE__ */ t(Fe, { className: "pf-v5-u-pb-lg", children: te(e, u.displayDescription, "") }),
          c.map((h) => /* @__PURE__ */ t(
            la,
            {
              t: e,
              form: n,
              supportedLocales: a,
              currentLocale: l,
              renderer: i,
              attribute: h
            },
            h.name
          ))
        ] })
      }))
    }
  );
}, la = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: a,
  currentLocale: l,
  attribute: s
}) => {
  const i = n.watch(
    Q(s.name)
  ), o = N(() => ia(s), [s]), u = s.multivalued || ca(i) && s.annotations?.inputType === void 0 ? qe["multi-input"] : qe[o];
  return s.name === "locale" ? /* @__PURE__ */ t(
    Yr,
    {
      form: n,
      supportedLocales: a,
      currentLocale: l,
      t: e,
      attribute: s
    }
  ) : /* @__PURE__ */ t(
    u,
    {
      t: e,
      form: n,
      inputType: o,
      attribute: s,
      renderer: r
    }
  );
}, sa = "text";
function ia(e) {
  if (Nt(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return oa(n) ? n : sa;
}
const oa = (e) => typeof e == "string" && e in qe, ca = (e) => Array.isArray(e) && e.length > 1, da = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ C(
  "svg",
  {
    className: hr(
      me.avatar,
      me.modifiers[r],
      n === "light" && me.modifiers.light,
      n === "dark" && me.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ C("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), ft = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...a
}) => {
  const [l, s] = w(!1);
  return /* @__PURE__ */ t(
    vn,
    {
      ...a,
      popperProps: {
        position: "right"
      },
      onOpenChange: (i) => s(i),
      toggle: (i) => /* @__PURE__ */ t(
        ce,
        {
          "data-testid": `${a["data-testid"]}-toggle`,
          ref: i,
          onClick: () => s(!l),
          isExpanded: l,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(ar, {}) : n
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(Cn, { children: r })
    }
  );
};
function ua(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, a = e.family_name, l = e.preferred_username;
  return r && a ? n("fullName", { givenName: r, familyName: a }) : r || a || l || n("unknownUser");
}
const Ya = ({
  keycloak: e,
  brand: { src: n, alt: r, className: a, ...l },
  avatar: s,
  features: {
    hasLogout: i = !0,
    hasManageAccount: o = !0,
    hasUsername: u = !0
  } = {},
  kebabDropdownItems: c,
  dropdownItems: h = [],
  toolbarItems: d,
  ...p
}) => {
  const { t: f } = L(), g = [];
  o && g.push(
    /* @__PURE__ */ t(
      lt,
      {
        onClick: () => e.accountManagement(),
        children: f("manageAccount")
      },
      "manageAccount"
    )
  ), i && g.push(
    /* @__PURE__ */ t(lt, { onClick: () => e.logout(), children: f("signOut") }, "signOut")
  );
  const T = e.idTokenParsed?.picture;
  return /* @__PURE__ */ C(In, { ...p, children: [
    /* @__PURE__ */ t(kn, { children: /* @__PURE__ */ t(wn, { variant: "plain", "aria-label": f("navigation"), children: /* @__PURE__ */ t(lr, {}) }) }),
    /* @__PURE__ */ t(xn, { ...l, children: /* @__PURE__ */ t("img", { src: n, alt: r, className: a }) }),
    /* @__PURE__ */ t(Tn, { children: /* @__PURE__ */ t(ye, { children: /* @__PURE__ */ C(Le, { children: [
      d?.map((b, y) => /* @__PURE__ */ t(H, { align: { default: "alignRight" }, children: b }, y)),
      /* @__PURE__ */ t(
        H,
        {
          visibility: {
            default: "hidden",
            md: "visible"
          },
          children: /* @__PURE__ */ t(
            ft,
            {
              "data-testid": "options",
              dropDownItems: [...h, g],
              title: u ? ua(e.idTokenParsed, f) : void 0
            }
          )
        }
      ),
      /* @__PURE__ */ t(
        H,
        {
          align: { default: "alignLeft" },
          visibility: {
            md: "hidden"
          },
          children: /* @__PURE__ */ t(
            ft,
            {
              "data-testid": "options-kebab",
              isKebab: !0,
              dropDownItems: [
                ...c || h,
                g
              ]
            }
          )
        }
      ),
      /* @__PURE__ */ t(
        H,
        {
          variant: "overflow-menu",
          align: { default: "alignRight" },
          className: "pf-v5-u-m-0-on-lg",
          children: T || s?.src ? /* @__PURE__ */ t(Sn, { src: T, alt: f("avatar"), ...s }) : /* @__PURE__ */ t(da, { ...s })
        }
      )
    ] }) }) })
  ] });
}, _t = Je("ErrorBoundaryContext", void 0), qt = () => Ze(_t);
class Qa extends Nn {
  state = {};
  static getDerivedStateFromError = (n) => ({ error: n });
  showBoundary = (n) => {
    this.setState({ error: n });
  };
  render() {
    return /* @__PURE__ */ t(
      _t.Provider,
      {
        value: { error: this.state.error, showBoundary: this.showBoundary },
        children: this.props.children
      }
    );
  }
}
const Xa = ({ children: e, fallback: n }) => {
  const { error: r } = qt();
  return r ? /* @__PURE__ */ t(n, { error: r }) : e;
};
function ha(e, n, r) {
  const { showBoundary: a } = qt();
  re(() => {
    const l = new AbortController(), { signal: s } = l;
    return e().then((i) => {
      s.aborted || n(i);
    }).catch((i) => {
      console.error(i), s.aborted || a(i);
    }), () => l.abort();
  }, r);
}
const fa = () => {
  const { t: e } = L();
  return /* @__PURE__ */ t(An, { children: /* @__PURE__ */ t(gt, { "aria-label": e("spinnerLoading") }) });
}, pa = ({
  message: e,
  instructions: n,
  onPrimaryAction: r,
  hasIcon: a = !0,
  isSearchVariant: l,
  primaryActionText: s,
  secondaryActions: i,
  icon: o,
  isDisabled: u = !1
}) => /* @__PURE__ */ C(En, { "data-testid": "empty-state", variant: "lg", children: [
  a && l ? /* @__PURE__ */ t(st, { icon: sr }) : a && /* @__PURE__ */ t(st, { icon: o || Rt }),
  /* @__PURE__ */ t(Rn, { titleText: e, headingLevel: "h1" }),
  /* @__PURE__ */ t(On, { children: n }),
  /* @__PURE__ */ C(Dn, { children: [
    s && /* @__PURE__ */ t(
      M,
      {
        "data-testid": `${e.replace(/\W+/g, "-").toLowerCase()}-empty-action`,
        variant: "primary",
        onClick: r,
        isDisabled: u,
        children: s
      }
    ),
    i && /* @__PURE__ */ t(Fn, { children: i.map((c) => /* @__PURE__ */ t(
      M,
      {
        "data-testid": `${c.text.replace(/\W+/g, "-").toLowerCase()}-empty-action`,
        variant: c.type || be.secondary,
        onClick: c.onClick,
        isDisabled: u,
        children: c.text
      },
      c.text
    )) })
  ] })
] }), ma = ({
  toolbarItem: e,
  subToolbar: n,
  toolbarItemFooter: r,
  children: a,
  searchTypeComponent: l,
  inputGroupName: s,
  inputGroupPlaceholder: i,
  inputGroupOnEnter: o
}) => {
  const { t: u } = L(), [c, h] = w(""), d = (f) => {
    h(f.trim()), o?.(f.trim());
  }, p = (f) => {
    f.key === "Enter" && d(c);
  };
  return /* @__PURE__ */ C(G, { children: [
    /* @__PURE__ */ t(ye, { children: /* @__PURE__ */ C(Le, { children: [
      s && /* @__PURE__ */ t(H, { children: /* @__PURE__ */ C(Ce, { "data-testid": s, children: [
        l,
        i && /* @__PURE__ */ t(
          Pn,
          {
            "data-testid": "table-search-input",
            placeholder: i,
            "aria-label": u("search"),
            value: c,
            onChange: (f, g) => {
              h(g);
            },
            onSearch: () => d(c),
            onKeyDown: p,
            onClear: () => d("")
          }
        )
      ] }) }),
      e
    ] }) }),
    n && /* @__PURE__ */ t(ye, { children: /* @__PURE__ */ t(Le, { children: n }) }),
    /* @__PURE__ */ t(Mn, {}),
    a,
    /* @__PURE__ */ t(ye, { children: r })
  ] });
}, pt = ({
  id: e,
  variant: n = "top",
  count: r,
  first: a,
  max: l,
  onNextClick: s,
  onPreviousClick: i,
  onPerPageSelect: o
}) => {
  const { t: u } = L(), c = Math.round(a / l);
  return /* @__PURE__ */ t(
    Ln,
    {
      widgetId: e,
      titles: {
        paginationAriaLabel: `${u("pagination")} ${n} `
      },
      isCompact: !0,
      toggleTemplate: ({
        firstIndex: h,
        lastIndex: d
      }) => /* @__PURE__ */ C("b", { children: [
        h,
        " - ",
        d
      ] }),
      itemCount: r + c * l,
      page: c + 1,
      perPage: l,
      onNextClick: (h, d) => s((d - 1) * l),
      onPreviousClick: (h, d) => i((d - 1) * l),
      onPerPageSelect: (h, d, p) => o(p - 1, d),
      variant: n
    }
  );
}, ga = ({
  count: e,
  searchTypeComponent: n,
  toolbarItem: r,
  subToolbar: a,
  children: l,
  inputGroupName: s,
  inputGroupPlaceholder: i,
  inputGroupOnEnter: o,
  ...u
}) => /* @__PURE__ */ t(
  ma,
  {
    searchTypeComponent: n,
    toolbarItem: /* @__PURE__ */ C(G, { children: [
      r,
      /* @__PURE__ */ t(H, { variant: "pagination", children: /* @__PURE__ */ t(pt, { count: e, ...u }) })
    ] }),
    subToolbar: a,
    toolbarItemFooter: e !== 0 ? /* @__PURE__ */ t(H, { variant: "pagination", children: /* @__PURE__ */ t(pt, { count: e, variant: "bottom", ...u }) }) : null,
    inputGroupName: s,
    inputGroupPlaceholder: i,
    inputGroupOnEnter: o,
    children: l
  }
);
function ya({
  columns: e,
  rows: n,
  actions: r,
  actionResolver: a,
  ariaLabelKey: l,
  onSelect: s,
  onCollapse: i,
  canSelectAll: o,
  isNotCompact: u,
  isRadio: c,
  ...h
}) {
  const { t: d } = L();
  return /* @__PURE__ */ C(
    mr,
    {
      ...h,
      variant: u ? void 0 : fr.compact,
      onSelect: s ? (p, f, g) => s(f, g) : void 0,
      onCollapse: i ? (p, f, g) => i(g, f) : void 0,
      selectVariant: "checkbox",
      canSelectAll: o,
      cells: e.map((p) => ({ ...p, title: d(p.displayKey || p.name) })),
      rows: n,
      actions: r,
      actionResolver: a,
      "aria-label": d(l),
      children: [
        /* @__PURE__ */ t(gr, {}),
        /* @__PURE__ */ t(yr, {})
      ]
    }
  );
}
function ba({
  ariaLabelKey: e,
  searchPlaceholderKey: n,
  isPaginated: r = !1,
  onSelect: a,
  canSelectAll: l = !1,
  isNotCompact: s,
  isRadio: i,
  detailColumns: o,
  isRowDisabled: u,
  loader: c,
  columns: h,
  actions: d,
  actionResolver: p,
  searchTypeComponent: f,
  toolbarItem: g,
  subToolbar: T,
  emptyState: b,
  icon: y,
  isSearching: R = !1,
  ...O
}) {
  const { t: k } = L(), [E, U] = w([]), [B, m] = w(), [S, F] = w(), [I, D] = w(!1), [P, Se] = Ft(
    localStorage,
    "pageSize",
    10
  ), [W, Ut] = w(P), [$, le] = w(0), [j, Ae] = w(""), Qe = Y(), [he, $t] = w(0), Xe = Y(), et = () => $t(he + 1), Kt = xt(), tt = (v, x) => v.map((A) => {
    if (A.cellRenderer) {
      const K = A.cellRenderer;
      return { title: /* @__PURE__ */ t(K, { ...x }) };
    }
    return q(x, A.name);
  }), nt = (v) => {
    const x = (A) => o?.[0]?.enabled?.(A);
    return v.map((A, K) => {
      const z = u ? u(A) : !1, V = [
        {
          data: A,
          disableSelection: z,
          disableActions: z,
          selected: !!E.find((rt) => {
            const Jt = q(A, "id") ?? q(A, "draftRecordId"), Zt = q(rt, "id") ?? q(rt, "draftRecordId");
            return Jt === Zt;
          }),
          isOpen: x(A) ? !1 : void 0,
          cells: tt(h, A)
        }
      ];
      return o && V.push({
        parent: K * 2,
        cells: x(A) ? tt(o, A) : []
      }), V;
    }).flat();
  }, Ee = (v) => ["string", "number"].includes(typeof v) ? v.toString() : v instanceof Array ? v.map(Ee).join("") : typeof v == "object" ? Ee(
    _n(v.title) ? v.title.props : Object.values(v)
  ) : "", fe = N(
    () => j === "" || r ? void 0 : nt(S || []).filter(
      (v) => v.cells.some(
        (x) => x && Ee(x).toLowerCase().includes(j.toLowerCase())
      )
    ).slice($, $ + W + 1),
    [j, $, W]
  );
  re(() => {
    if (l) {
      const v = document.getElementsByClassName("pf-v5-c-table__check").item(0);
      if (v) {
        const x = v.children.item(
          0
        );
        x.indeterminate = E.length > 0 && E.length < (fe || B).length;
      }
    }
  }, [E]), ha(
    async () => {
      D(!0);
      const v = Qe.current === "" && j !== "";
      return v && le(0), Qe.current = j, typeof c == "function" ? he === Xe.current && S ? S : await c(v ? 0 : $, W + 1, j) : c;
    },
    (v) => {
      Xe.current = he, r || (F(v), v.length > $ ? v = v.slice($, $ + W + 1) : le(0));
      const x = nt(v);
      m(x), D(!1);
    },
    [
      he,
      $,
      W,
      j,
      typeof c != "function" ? c : void 0
    ]
  );
  const Ht = () => d && dr(d).map((v, x) => (delete v.onRowClick, v.onClick = async (A, K) => {
    await d[x].onRowClick(
      (fe || B)[K].data
    ) && (r || Ae(""), et());
  }, v)), Gt = (v, x) => {
    const A = fe || B;
    if (i) {
      A.forEach((z) => {
        z.selected = !1;
      }), A[x].selected = v, m([...B]);
      const K = v ? [A[x].data] : [];
      U(K), a(K);
    } else {
      x === -1 ? m(
        A.map((V) => (V.selected = v, V))
      ) : (A[x].selected = v, m([...B]));
      const z = [
        ...ur(
          E,
          A.map((V) => V.data),
          "id"
        ),
        ...A.filter((V) => V.selected).map((V) => V.data)
      ];
      U(z), a(z);
    }
  }, Wt = (v, x) => {
    X[x].isOpen = v, m([...X]);
  }, X = fe || B, pe = !X || X.length === 0, Re = j !== "" || R, jt = o ? W * 2 : W, zt = o ? (X?.length || 0) / 2 : X?.length || 0;
  return /* @__PURE__ */ C(G, { children: [
    (I || !pe || Re) && /* @__PURE__ */ C(
      ga,
      {
        id: Kt,
        count: zt,
        first: $,
        max: W,
        onNextClick: le,
        onPreviousClick: le,
        onPerPageSelect: (v, x) => {
          le(v), Ut(x), Se(x);
        },
        inputGroupName: n ? `${e}input` : void 0,
        inputGroupOnEnter: Ae,
        inputGroupPlaceholder: k(n || ""),
        searchTypeComponent: f,
        toolbarItem: /* @__PURE__ */ C(G, { children: [
          g,
          " ",
          /* @__PURE__ */ t(H, { variant: "separator" }),
          " ",
          /* @__PURE__ */ t(H, { children: /* @__PURE__ */ C(M, { variant: "link", onClick: et, children: [
            /* @__PURE__ */ t(ir, {}),
            " ",
            k("refresh")
          ] }) })
        ] }),
        subToolbar: T,
        children: [
          !I && !pe && /* @__PURE__ */ t(
            ya,
            {
              ...O,
              canSelectAll: l,
              onSelect: a ? Gt : void 0,
              onCollapse: o ? Wt : void 0,
              actions: Ht(),
              actionResolver: p,
              rows: X.slice(0, jt),
              columns: h,
              isNotCompact: s,
              isRadio: i,
              ariaLabelKey: e
            }
          ),
          !I && pe && Re && /* @__PURE__ */ t(
            pa,
            {
              hasIcon: !0,
              icon: y,
              isSearchVariant: !0,
              message: k("noSearchResults"),
              instructions: k("noSearchResultsInstructions"),
              secondaryActions: R ? [] : [
                {
                  text: k("clearAllFilters"),
                  onClick: () => Ae(""),
                  type: be.link
                }
              ]
            }
          ),
          I && /* @__PURE__ */ t(fa, {})
        ]
      }
    ),
    !I && pe && !Re && b
  ] });
}
const va = ({ link: e, organization: n }) => {
  const { t: r } = L();
  return /* @__PURE__ */ t(pr, { wrapModifier: "truncate", children: /* @__PURE__ */ C(e, { organization: n, children: [
    n.name,
    !n.enabled && /* @__PURE__ */ t(
      Bn,
      {
        isRead: !0,
        className: "pf-v5-u-ml-sm",
        children: r("disabled")
      },
      `${n.id}-disabled`
    )
  ] }) });
}, Ca = (e) => {
  const { t: n } = L();
  return /* @__PURE__ */ t(
    We,
    {
      numChips: 2,
      expandedText: n("hide"),
      collapsedText: n("showRemaining"),
      children: e.domains?.map((r) => {
        const a = typeof r == "string" ? r : r.name;
        return /* @__PURE__ */ t(je, { isReadOnly: !0, children: a }, a);
      })
    }
  );
}, el = ({
  loader: e,
  toolbarItem: n,
  isPaginated: r = !1,
  isSearching: a = !1,
  onSelect: l,
  onDelete: s,
  deleteLabel: i = "delete",
  link: o,
  children: u
}) => {
  const { t: c } = L();
  return /* @__PURE__ */ t(
    ba,
    {
      loader: e,
      isPaginated: r,
      isSearching: a,
      ariaLabelKey: "organizationList",
      toolbarItem: n,
      onSelect: l,
      canSelectAll: l !== void 0,
      actions: s ? [
        {
          title: c(i),
          onRowClick: s
        }
      ] : void 0,
      columns: [
        {
          name: "name",
          displayKey: "name",
          cellRenderer: (h) => /* @__PURE__ */ t(va, { link: o, organization: h })
        },
        {
          name: "domains",
          displayKey: "domains",
          cellRenderer: Ca
        },
        {
          name: "description",
          displayKey: "description"
        },
        {
          name: "membershipType",
          displayKey: "membershipType"
        }
      ],
      emptyState: u
    }
  );
};
export {
  Ar as AlertProvider,
  Na as ContinueCancelModal,
  Xa as ErrorBoundaryFallback,
  Qa as ErrorBoundaryProvider,
  Er as ErrorPage,
  Mt as FormErrorText,
  Kr as FormPanel,
  Ga as FormSubmitButton,
  Fr as Help,
  Lt as HelpItem,
  Ha as IconMapper,
  ba as KeycloakDataTable,
  Ya as KeycloakMasthead,
  Ba as KeycloakProvider,
  ra as KeycloakSelect,
  fa as KeycloakSpinner,
  Nr as KeycloakTextArea,
  pa as ListEmptyState,
  _a as NumberControl,
  el as OrganizationTable,
  ga as PaginatingTableToolbar,
  qa as PasswordControl,
  Bt as PasswordInput,
  zr as ScrollForm,
  Vr as SelectControl,
  se as SelectVariant,
  Ua as SwitchControl,
  ma as TableToolbar,
  $a as TextAreaControl,
  Ka as TextControl,
  Za as UserProfileFields,
  Wa as beerify,
  Je as createNamedContext,
  ja as debeerify,
  kr as generateId,
  Cr as getErrorDescription,
  vr as getErrorMessage,
  Va as getInjectedEnvironment,
  Ot as getNetworkErrorDescription,
  Ir as getNetworkErrorMessage,
  wr as isDefined,
  Ja as isUserProfileError,
  te as label,
  jr as mainPageContentId,
  za as setUserProfileServerError,
  Ma as useAlerts,
  La as useEnvironment,
  qt as useErrorBoundary,
  ha as useFetch,
  Dr as useHelp,
  Ze as useRequiredContext,
  xr as useSetTimeout,
  Ft as useStoredState
};
