import Resource from "./resource.js";
export class TideProvider extends Resource {
    getRequiredActionLink = this.makeRequest({
        method: "POST",
        path: "/tideAdminResources/get-required-action-link",
        payloadKey: "actions",
        queryParamKeys: ["lifespan", "redirectUri", "clientId", "userId"],
        keyTransform: {
            clientId: "client_id",
            redirectUri: "redirect_uri",
        },
    });
    addAuthorization = this.makeRequest({
        method: "POST",
        path: "/tideAdminResources/add-authorization",
    });
    addRejection = this.makeRequest({
        method: "POST",
        path: "/tideAdminResources/add-rejection",
    });
    saveFirstAdminAuthorizer = this.makeRequest({
        method: "POST",
        path: "/vendorResources/first-admin-authorizer"
    });
    getVouchers = this.makeRequest({
        method: "POST",
        path: "/tideAdminResources/new-voucher"
    });
    rotateVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/rotate-vrk"
    });
    getScheduledTasks = this.makeRequest({
        method: "GET",
        path: "/vendorResources/scheduledTasks",
    });
    getLicenseHistory = this.makeRequest({
        method: "GET",
        path: "/vendorResources/licenseHistory",
    });
    triggerScheduledTask = this.makeRequest({
        method: "POST",
        path: "/vendorResources/scheduledTasks/{taskName}/trigger",
        urlParamKeys: ["taskName"],
        catchNotFound: true,
    });
    scheduleGenVRKTask = this.makeRequest({
        method: "POST",
        path: "/vendorResources/scheduledTasks/genVRK/schedule",
    });
    uploadImage = this.makeRequest({
        method: "POST",
        path: "/tide-idp-admin-resources/images/upload",
    });
    getImageName = this.makeRequest({
        method: "GET",
        path: "/tide-idp-admin-resources/images/{type}/name",
        urlParamKeys: ["type"],
        catchNotFound: true,
    });
    deleteImage = this.makeRequest({
        method: "DELETE",
        path: "/tide-idp-admin-resources/images/{type}/delete",
        urlParamKeys: ["type"],
        catchNotFound: true,
    });
    generateInitialKey = this.makeRequest({
        method: "POST",
        path: "/vendorResources/generate-initial-key",
    });
    signIdpSettings = this.makeRequest({
        method: "POST",
        path: "/vendorResources/sign-idp-settings",
    });
    generateInitialVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/generate-initial-vrk",
    });
    confirmInitialVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/confirm-initial-vrk",
    });
    clearTempVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/clear-temp-vrk",
    });
    generateVendorId = this.makeRequest({
        method: "POST",
        path: "/vendorResources/generate-vendor-id",
    });
    signMessage = this.makeRequest({
        method: "POST",
        path: "/vendorResources/sign-message",
    });
    authorizeStripeRequest = this.makeRequest({
        method: "POST",
        path: "/vendorResources/authorize-stripe-request",
    });
    createStripeCheckoutSession = this.makeRequest({
        method: "POST",
        path: "/vendorResources/createStripeCheckoutSession",
    });
    isPendingLicenseActive = this.makeRequest({
        method: "GET",
        path: "/vendorResources/isPendingLicenseActive",
    });
    getLicenseDetails = this.makeRequest({
        method: "GET",
        path: "/vendorResources/getLicenseDetails",
    });
    getSubscriptionStatus = this.makeRequest({
        method: "GET",
        path: "/vendorResources/getSubscriptionStatus",
    });
    createCustomerPortalSession = this.makeRequest({
        method: "POST",
        path: "/vendorResources/createCustomerPortalSession",
    });
    updateSubscription = this.makeRequest({
        method: "POST",
        path: "/vendorResources/updateSubscription",
    });
    cancelSubscription = this.makeRequest({
        method: "GET",
        path: "/vendorResources/cancelSubscription",
    });
    getInstallationProviders = this.makeRequest({
        method: "GET",
        path: "/vendorResources/get-installations-provider",
        queryParamKeys: ["clientId", "providerId"],
    });
    getTideJwk = this.makeRequest({
        method: "GET",
        path: "/vendorResources/get-tide-jwk",
    });
    toggleIGA = this.makeRequest({
        method: "POST",
        path: "/tideAdminResources/toggle-iga",
    });
    triggerLicenseRenewedEvent = this.makeRequest({
        method: "GET",
        urlParamKeys: ["error"],
        path: "/vendorResources/triggerLicenseRenewedEvent/{error}"
    });
    triggerVendorKeyCreationEvent = this.makeRequest({
        method: "GET",
        urlParamKeys: ["error"],
        path: "/vendorResources/triggerVendorKeyCreationEvent/{error}"
    });
    triggerAuthorizerUpdateEvent = this.makeRequest({
        method: "GET",
        urlParamKeys: ["error"],
        path: "/vendorResources/triggerAuthorizerUpdateEvent/{error}"
    });
    triggerAuthorizeEvent = this.makeRequest({
        method: "GET",
        urlParamKeys: ["error"],
        path: "/vendorResources/triggerAuthorizeEvent/{error}"
    });
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}
